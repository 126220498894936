(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/mutation-observer.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/mutation-observer.js');
"use strict";

const {
  classNameToArray,
  checkChanges
} = svs.templates.primaryOnboarding.utils;
const {
  hasIdOfInterest,
  hasClassNameOfInterest,
  getTargetsForClassname,
  getTargetsForId
} = svs.templates.primaryOnboarding.target;
const {
  getHintsFromTarget
} = svs.templates.primaryOnboarding.onboardingInfo;
const {
  updateHintWithChanges
} = svs.templates.primaryOnboarding.hintItem;
const {
  addIntersectionObserver,
  removeIntersectionObserver
} = svs.templates.primaryOnboarding.intersectionObserver;

const unknownTargets = new Set();
const knownTargets = new Map();
const addUnknownTarget = target => {
  unknownTargets.add(target);
};
const removeUnknownTarget = target => {
  unknownTargets.delete(target);
};
const addKnownTarget = (el, target) => {
  removeUnknownTarget(target);
  const hints = getHintsFromTarget(target);
  hints.forEach(hint => {
    updateHintWithChanges(hint, {
      isAvailable: true,
      targetEl: el
    });
  });
  knownTargets.set(el, target);
  addIntersectionObserver(el);
};
const removeKnownTarget = el => {
  const target = knownTargets.get(el);
  if (target) {
    const hints = getHintsFromTarget(target);
    hints.forEach(hint => {
      updateHintWithChanges(hint, {
        isAvailable: false,
        targetEl: null
      });
    });
    addUnknownTarget(target);
  }
  knownTargets.delete(el);
  removeIntersectionObserver(el);
};
const lookForUnknonwTargets = node => {
  if (node.nodeType === Node.ELEMENT_NODE) {
    unknownTargets.forEach(target => {
      const el = node.querySelector(target);
      if (el) {
        addKnownTarget(el, target);
      }
    });
  }
};
const lookForKnownTargets = node => {
  if (node.nodeType === Node.ELEMENT_NODE) {
    knownTargets.forEach(target => {
      const el = node.querySelector(target);
      if (el) {
        removeKnownTarget(el);
      }
    });
  }
};
const mutationObserverCallback = list => {
  list.forEach(mutation => {
    if (mutation.type === 'childList') {
      mutation.addedNodes.forEach(n => {
        lookForUnknonwTargets(n);
      });
      mutation.removedNodes.forEach(n => {
        lookForKnownTargets(n);
      });
    } else if (mutation.type === 'attributes') {
      let newValue;
      let oldValue;
      let newIsOfInterest;
      if (mutation.attributeName === 'id') {
        const idTarget = mutation.target.id || mutation.oldValue;
        if (hasIdOfInterest(idTarget)) {
          const valueAppeared = mutation.target.id !== undefined && mutation.oldValue === undefined;
          const valueDisappeared = mutation.target.id === undefined && mutation.oldValue !== undefined;
          const targets = getTargetsForId(idTarget);
          targets.forEach(targetQuery => {
            const foundTarget = mutation.target === mutation.target.parentElement.querySelector(targetQuery);
            if (foundTarget && valueAppeared) {
              newIsOfInterest = true;
              newValue = "#".concat(mutation.target.id);
            } else if (!foundTarget && valueDisappeared) {
              oldValue = "#".concat(mutation.oldValue);
            }
          });
        }
      } else if (mutation.attributeName === 'class') {
        const changes = checkChanges(classNameToArray(mutation.oldValue), classNameToArray(mutation.target.className));
        changes.forEach((value, key) => {
          if (hasClassNameOfInterest(key)) {
            const targets = getTargetsForClassname(key);
            const valueAppeared = value.newValue !== undefined && value.oldValue === undefined;
            const valueDisappeared = value.newValue === undefined && value.oldValue !== undefined;
            targets.forEach(targetQuery => {
              const foundTarget = mutation.target === mutation.target.parentElement.querySelector(targetQuery);
              if (foundTarget && valueAppeared) {
                newIsOfInterest = true;
                newValue = ".".concat(key);
              } else if (!foundTarget && valueDisappeared) {
                oldValue = ".".concat(key);
              }
            });
          }
        });
      }
      if (newValue && newIsOfInterest) {
        addKnownTarget(mutation.target, newValue);
      }
      if (oldValue) {
        removeKnownTarget(mutation.target);
      }
    }
  });
};
const mutationObserver = new MutationObserver(mutationObserverCallback);
const connectMutationObserver = (el, opt) => {
  mutationObserver.observe(el, opt);
};
const disconnectMutationObserver = () => {
  mutationObserver.disconnect();
};
setGlobal('svs.templates.primaryOnboarding.mutationObserver', {
  addKnownTarget,
  addUnknownTarget,
  connectMutationObserver,
  disconnectMutationObserver
});

 })(window);