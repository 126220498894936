(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/fullscreen-mount.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/fullscreen-mount.js');
"use strict";

var _svs$onboarding;

const {
  onboardingDomain
} = svs.components.lbUtils.onboarding.utils;
const {
  createFullscreenEventHandler
} = svs.components.lbUtils.onboarding.events.eventHandlers;
const {
  FullscreenEventType
} = svs.components.lbUtils.onboarding.events.constants;
const {
  TemplateSelectors
} = svs.templates.primaryOnboarding.constants;
const {
  setItem,
  getItem
} = svs.templates.primaryOnboarding.sessionStorage;


const onboardingContentEl = document.querySelector(TemplateSelectors.TemplateContent);
const mainContentEl = onboardingContentEl.querySelector(TemplateSelectors.MainContent);
const onboardingFullscreenEl = document.querySelector(TemplateSelectors.TemplateFullscreen);
const {
  key,
  count,
  displaycount
} = ((_svs$onboarding = svs.onboarding) === null || _svs$onboarding === void 0 || (_svs$onboarding = _svs$onboarding.fullscreen) === null || _svs$onboarding === void 0 ? void 0 : _svs$onboarding.data) || {};
if (mainContentEl && onboardingFullscreenEl && key) {
  const {
    hasTriggered
  } = getItem(key) || {
    hasTriggered: false
  };
  if (!hasTriggered) {
    const {
      addEventListener: addFullscreenEventListener
    } = createFullscreenEventHandler();
    const {
      setCount
    } = onboardingDomain(svs.core.data.module, svs.core.data.baseUrl);
    const updateCount = () => {
      if (displaycount > 0 && count < displaycount) {
        setCount(key, count + 1);
      }
    };
    const showOnboardingContent = () => {
      mainContentEl.classList.add('onboarding_content--show');
      mainContentEl.classList.remove('onboarding_content--hidden');
    };
    const hideOnboardingContent = () => {
      mainContentEl.classList.add('onboarding_content--hidden');
      mainContentEl.classList.remove('onboarding_content--show');
    };
    const showOnboardingFullscreen = () => {
      onboardingFullscreenEl.classList.add('onboarding_fullscreen--show');
      onboardingFullscreenEl.classList.remove('onboarding_fullscreen--hidden');
    };
    const hideOnboardingFullscreen = () => {
      onboardingFullscreenEl.classList.add('onboarding_fullscreen--hidden');
      onboardingFullscreenEl.classList.remove('onboarding_fullscreen--show');
    };
    const onCloseFullscreen = () => {
      hideOnboardingFullscreen();
      showOnboardingContent();
      onboardingFullscreenEl.parentElement.removeChild(onboardingFullscreenEl);
    };
    mainContentEl.classList.add('onboarding_content');
    addFullscreenEventListener(FullscreenEventType.Show, e => {
      const FullscreenComponent = e.detail.component;
      ReactDOM.render(React.createElement(FullscreenComponent, {
        onCloseFullscreen: onCloseFullscreen
      }), onboardingFullscreenEl);
      updateCount();
      setItem(key, {
        hasTriggered: true
      });
      hideOnboardingContent();
      showOnboardingFullscreen();
    });
    addFullscreenEventListener(FullscreenEventType.Hide, () => {
      onCloseFullscreen();
    });
  }
}

 })(window);