(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/tooltip.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/tooltip.js');
"use strict";

const {
  CreatedTooltipEvent,
  RemovedTooltipEvent
} = svs.components.lbUtils.onboarding.events;
const {
  TooltipEventType
} = svs.components.lbUtils.onboarding.events.constants;
const {
  TemplateSelectors
} = svs.templates.primaryOnboarding.constants;

const onboardingContentEl = document.querySelector(TemplateSelectors.TemplateContent);
const arrowSize = parseInt(getComputedStyle(onboardingContentEl).getPropertyValue('--onboarding-arrow-size'), 10);
const tooltips = new Map();
const classNames = {
  Main: 'pg_onboarding_tooltip',
  MainNoClick: 'pg_onboarding_tooltip--noclick',
  Box: 'pg_onboarding_tooltip__box',
  Content: 'pg_onboarding_tooltip__box__content',
  Paragraph: 'pg_onboarding_tooltip__box__content__paragraph',
  Arrow: 'pg_onboarding_tooltip__arrow'
};
const createTooltip = texts => {
  const tooltipEl = document.createElement('div');
  tooltipEl.className = classNames.Main;
  tooltipEl.setAttribute('role', 'tooltip');
  const tooltipBox = document.createElement('div');
  tooltipBox.className = classNames.Box;
  tooltipEl.appendChild(tooltipBox);
  const tooltipContent = document.createElement('div');
  tooltipContent.className = classNames.Content;
  tooltipBox.appendChild(tooltipContent);
  texts.forEach(text => {
    if (text.length > 0) {
      const pEl = document.createElement('p');
      pEl.className = classNames.Paragraph;
      pEl.appendChild(document.createTextNode(text));
      tooltipContent.appendChild(pEl);
    }
  });
  const arrowEl = document.createElement('div');
  arrowEl.className = classNames.Arrow;
  arrowEl.setAttribute('data-popper-arrow', '');
  tooltipEl.appendChild(arrowEl);
  return tooltipEl;
};
const deleteTooltip = (tooltipEl, target, ref) => {
  try {
    if (tooltips.has(target)) {
      tooltips.delete(target);
    }
    onboardingContentEl.removeChild(tooltipEl);
    onboardingContentEl.dispatchEvent(new RemovedTooltipEvent({
      tooltip: tooltipEl,
      target,
      ref
    }));
  } catch (_unused) {
  }
};
const removeTooltip = target => {
  if (tooltips.has(target)) {
    const {
      el,
      ref
    } = tooltips.get(target);
    if (el) {
      deleteTooltip(el, target, ref);
    }
  }
};
const addTooltip = (target, texts, placement, preventClick, timeout, ref) => {
  const tooltipTargetEl = onboardingContentEl.querySelector(target);
  if (tooltipTargetEl && !tooltips.has(target)) {
    const tooltipEl = createTooltip(texts);
    let tooltipTimeout;
    const onTooltipClick = () => {
      if (tooltipTimeout) {
        window.clearTimeout(tooltipTimeout);
      }
      deleteTooltip(tooltipEl, target, ref);
    };
    if (!preventClick) {
      tooltipTargetEl.addEventListener('click', onTooltipClick, {
        once: true
      });
      tooltipEl.addEventListener('click', onTooltipClick, {
        once: true
      });
    } else {
      tooltipEl.classList.add(classNames.MainNoClick);
    }
    if (timeout > 0) {
      tooltipTimeout = window.setTimeout(() => {
        window.clearTimeout(tooltipTimeout);
        tooltipTargetEl.removeEventListener('click', onTooltipClick);
        deleteTooltip(tooltipEl, target, ref);
      }, timeout * 1000);
    }
    onboardingContentEl.appendChild(tooltipEl);
    Popper.createPopper(tooltipTargetEl, tooltipEl, {
      placement,
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, arrowSize]
        }
      }]
    });
    tooltips.set(target, {
      texts,
      placement,
      preventClick,
      timeout,
      ref,
      el: tooltipEl
    });
    onboardingContentEl.dispatchEvent(new CreatedTooltipEvent({
      tooltip: tooltipEl,
      target,
      ref
    }));
  }
};
onboardingContentEl.addEventListener(TooltipEventType.Add, e => {
  const {
    target,
    text,
    placement,
    preventClick,
    timeout,
    ref
  } = e.detail;
  addTooltip(target, text, placement, preventClick, timeout, ref);
});
onboardingContentEl.addEventListener(TooltipEventType.Delete, e => {
  const {
    target
  } = e.detail;
  removeTooltip(target);
});

 })(window);