(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/onboarding-state.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/onboarding-state.js');
"use strict";

var _svs$onboarding;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  AddTooltipEvent,
  DeleteTooltipEvent
} = svs.components.lbUtils.onboarding.events;
const {
  createToolTipEventHandler
} = svs.components.lbUtils.onboarding.events.eventHandlers;
const {
  TooltipEventType
} = svs.components.lbUtils.onboarding.events.constants;
const {
  onboardingDomain
} = svs.components.lbUtils.onboarding.utils;
const {
  HintVisibility,
  TemplateSelectors
} = svs.templates.primaryOnboarding.constants;
const {
  addTargetOfInterest,
  removeTargetOfInteresetByKey,
  hasTargetOfInterest,
  getInterestsSize
} = svs.templates.primaryOnboarding.target;
const {
  getHintFromKey,
  addTargetRef,
  forEachOnboardingInfo,
  findOnboardingInfo
} = svs.templates.primaryOnboarding.onboardingInfo;
const {
  addNewHint,
  updateHintWithChanges,
  registerHintHooks
} = svs.templates.primaryOnboarding.hintItem;
const {
  addKnownTarget,
  addUnknownTarget,
  connectMutationObserver,
  disconnectMutationObserver
} = svs.templates.primaryOnboarding.mutationObserver;
const {
  removeIntersectionObserver,
  disconnectIntersectionObserver
} = svs.templates.primaryOnboarding.intersectionObserver;
const {
  log: logger
} = svs.components.lbUtils.onboarding;
const {
  hints
} = ((_svs$onboarding = svs.onboarding) === null || _svs$onboarding === void 0 || (_svs$onboarding = _svs$onboarding.contextualOnboarding) === null || _svs$onboarding === void 0 ? void 0 : _svs$onboarding.data) || {
  hints: {}
};

const hintValues = Object.values(hints);
const onboardingContentEl = document.querySelector(TemplateSelectors.TemplateContent);
if (onboardingContentEl && hintValues.length > 0) {
  const {
    setCount
  } = onboardingDomain(svs.core.data.module, svs.core.data.baseUrl);
  const {
    dispatchEvent: dispatchTooltipEvent
  } = createToolTipEventHandler();
  const {
    addEventListener: addTooltipEventListener
  } = createToolTipEventHandler();
  const closeDownObservers = () => {
    logger.debug('Closing down observers');
    disconnectIntersectionObserver();
    disconnectMutationObserver();
  };
  const removeTargetOfInterest = key => {
    const hint = getHintFromKey(key);
    removeTargetOfInteresetByKey(key);
    if (!hasTargetOfInterest(hint.target)) {
      logger.debug("Target is boring ".concat(hint.target, ". Closing down observers"));
      removeIntersectionObserver(hint.targetEl, hint);
    }
    if (getInterestsSize() === 0) {
      logger.debug('Interest reached 0. Closing down observers');
      closeDownObservers();
    } else {
      logger.debug("Interest is at ".concat(getInterestsSize()));
    }
  };
  const mountHintItem = hintItem => {
    addTargetRef(hintItem.target, hintItem.key);
    addTargetOfInterest(hintItem.target, hintItem.key);
    const targetEl = onboardingContentEl.querySelector(hintItem.target);
    if (targetEl) {
      addKnownTarget(targetEl, hintItem.target);
    } else {
      addUnknownTarget(hintItem.target);
    }
  };
  const addToolTip = hint => {
    if (!hint.hasTriggered && !hint.isBlocked) {
      logger.debug("Dispatching tooltip add event! ".concat(hint.key));
      dispatchTooltipEvent(new AddTooltipEvent(_objectSpread(_objectSpread({}, hint), {}, {
        ref: hint.key
      })));
    } else if (hint.isBlocked) {
      logger.debug("Cant add tooltip because its blocked! ".concat(hint.dependency, " -> ").concat(hint.key));
    } else if (hint.hasTriggered) {
      logger.debug("Cant add tooltip because its already triggerd! ".concat(hint.key));
    }
  };
  const removeToolTip = hint => {
    dispatchTooltipEvent(new DeleteTooltipEvent(hint));
  };
  registerHintHooks({
    visibility: (_oldValue, newValue, _oldHint, newHint) => {
      if (newValue === HintVisibility.Visible) {
        addToolTip(newHint);
      } else if (newValue === HintVisibility.None) {
        removeToolTip(newHint);
        removeTargetOfInterest(newHint.key);
      }
    },
    count: (_oldValue, newValue, _oldHint, newHint) => {
      if (newValue && newHint.displaycount > 0) {
        logger.debug('Count changed!', newValue);
        setCount(newHint.key, newValue);
      }
    },
    isBlocked: (oldValue, newValue, _oldHint, newHint) => {
      if (oldValue && !newValue && newHint.visibility === HintVisibility.Visible) {
        addToolTip(newHint);
      }
    }
  });
  hintValues.forEach(hint => {
    addNewHint(hint);
  });
  forEachOnboardingInfo(hintItem => {
    if (hintItem.dependency && hintItem.dependency.length > 0) {
      const [, dependencyHint] = findOnboardingInfo(_ref => {
        let [, h] = _ref;
        return h.key === hintItem.dependency;
      }) || [undefined, undefined];
      if (!dependencyHint || dependencyHint.hasTriggered) {
        hintItem.dependency = undefined;
        hintItem.isBlocked = false;
      } else {
        dependencyHint.child = hintItem.key;
        hintItem.isBlocked = true;
      }
    }
    if (!hintItem.hasTriggered) {
      mountHintItem(hintItem);
    }
  });
  addTooltipEventListener(TooltipEventType.Created, e => {
    logger.debug('Tooltip created', e.detail.target);
    const hint = getHintFromKey(e.detail.ref);
    updateHintWithChanges(hint, {
      tooltipEl: e.detail.tooltip,
      hasTriggered: true
    });
  });
  addTooltipEventListener(TooltipEventType.Removed, e => {
    logger.debug('Tooltip removed', e.detail.target);
    const hint = getHintFromKey(e.detail.ref);
    removeTargetOfInterest(hint.key);
    let count = hint.count;
    if (hint.displaycount > 0 && hint.count < hint.displaycount) {
      count++;
    }
    updateHintWithChanges(hint, {
      tooltipEl: null,
      count
    });
    if (hint.child && hint.child.length > 0) {
      const childHint = getHintFromKey(hint.child);
      updateHintWithChanges(childHint, {
        isBlocked: false
      });
    }
  });
  window.addEventListener('beforeunload', () => {
    closeDownObservers();
  });
  const mutationObserverOpt = {
    childList: true,
    subtree: true,
    attributes: true,
    attributeOldValue: true,
    attributeFilter: ['id', 'class']
  };
  connectMutationObserver(onboardingContentEl, mutationObserverOpt);
}

 })(window);