(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/intersection-observer.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/intersection-observer.js');
"use strict";


const {
  updateHintWithChanges
} = svs.templates.primaryOnboarding.hintItem;
const {
  getHintsFromClassname,
  getHintsFromId
} = svs.templates.primaryOnboarding.onboardingInfo;

const intersectionObserverOpt = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
  trackVisibility: true,
  delay: 200
};
const intersectionObserverCallback = entries => {
  entries.forEach(entry => {
    const tooltipTargetEl = entry.target;
    const {
      id,
      classList
    } = tooltipTargetEl;
    let hints;
    if (id && id.length > 0) {
      hints = getHintsFromId(id);
    }
    if (!hints && classList && classList.length > 0) {
      classList.forEach(c => {
        hints = hints || getHintsFromClassname(c);
      });
    }
    if (hints) {
      hints.forEach(hint => {
        updateHintWithChanges(hint, {
          isVisible: entry.isVisible || entry.isIntersecting
        });
      });
    }
  });
};
const intersectionObserver = new IntersectionObserver(intersectionObserverCallback, intersectionObserverOpt);
const addIntersectionObserver = el => {
  if (el && el instanceof Element) {
    intersectionObserver.observe(el);
  }
};
const removeIntersectionObserver = el => {
  if (el && el instanceof Element) {
    intersectionObserver.unobserve(el);
  }
};
const disconnectIntersectionObserver = () => {
  intersectionObserver.disconnect();
};

setGlobal('svs.templates.primaryOnboarding.intersectionObserver', {
  addIntersectionObserver,
  removeIntersectionObserver,
  disconnectIntersectionObserver
});

 })(window);