(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/log.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/log.js');
"use strict";

if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  module.exports = trinidad.logger('onboarding');
} else {
  const log = svs.core.log.getLogger('onboarding');
  setGlobal('svs.components.lbUtils.onboarding.log', log);
}

 })(window);