(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/session-storage.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/session-storage.js');
"use strict";

const {
  verifyValue
} = svs.components.lbUtils.verify;
const {
  log: logger
} = svs.components.lbUtils.onboarding;

const sessionValueKey = 'sessionValue';
const runSessionLogic = fn => {
  let a;
  try {
    a = fn();
  } catch (err) {
    logger.debug(err);
  }
  return a;
};
const stringifyValue = value => {
  let obj = {};
  if (!verifyValue(value).dontThrow().isObject().isValid || value[sessionValueKey]) {
    obj[sessionValueKey] = value;
  } else {
    obj = value;
  }
  return JSON.stringify(obj);
};
const parseStringToValue = str => {
  if (!str) {
    return str;
  }
  const obj = JSON.parse(str);
  const objKeys = Object.keys(obj);
  return objKeys.includes(sessionValueKey) ? obj[sessionValueKey] : obj;
};
const setItem = (key, value) => {
  runSessionLogic(() => {
    sessionStorage.setItem(key, stringifyValue(value));
  });
};
const getItem = key => runSessionLogic(() => parseStringToValue(sessionStorage.getItem(key)));
const removeItem = key => {
  runSessionLogic(() => {
    sessionStorage.removeItem(key);
  });
};
const clear = () => {
  runSessionLogic(() => {
    sessionStorage.clear();
  });
};
setGlobal('svs.templates.primaryOnboarding.sessionStorage', {
  setItem,
  getItem,
  removeItem,
  clear
});

 })(window);