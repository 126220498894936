(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/target.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/target.js');
"use strict";


const {
  TargetType
} = svs.templates.primaryOnboarding.constants;
const {
  breakDownTarget,
  splitCsvString,
  splitClassnameString,
  splitIdString
} = svs.templates.primaryOnboarding.utils;

const clearupInterestMaps = (map, key) => {
  const clearMapKeys = [];
  map.forEach((keySet, mapKey) => {
    if (keySet.has(key)) {
      keySet.delete(key);
    }
    if (keySet.size === 0) {
      clearMapKeys.push(mapKey);
    }
  });
  clearMapKeys.forEach(mapKey => {
    map.delete(mapKey);
  });
};
const addToInterestMapSet = (map, key, value) => {
  const set = map.get(key);
  if (!set) {
    map.set(key, new Set([value]));
  } else if (set.has(value)) {
    set.add(value);
  }
};
const targetsOfIntrest = new Map();
const classOfIntrest = new Map();
const idOfIntrest = new Map();
const clearupClassOfIntrest = key => {
  clearupInterestMaps(classOfIntrest, key);
};
const addToClassOfIntrest = (key, value) => {
  addToInterestMapSet(classOfIntrest, key, value);
};
const clearupIdOfIntrest = key => {
  clearupInterestMaps(idOfIntrest, key);
};
const addToIdOfIntrest = (key, value) => {
  addToInterestMapSet(idOfIntrest, key, value);
};
const breakDownSelector = target => splitClassnameString(target, s => splitIdString(s));
const breakDownTargets = target => splitCsvString(target).map(s => breakDownSelector(s));
const addTargetOfInterest = (target, key) => {
  targetsOfIntrest.set(key, target);
  const targetSplit = breakDownTargets(target).flat();
  targetSplit.forEach(target => {
    const [targetType, cleanTarget] = breakDownTarget(target);
    if (targetType === TargetType.Classname) {
      addToClassOfIntrest(cleanTarget, key);
    } else if (targetType === TargetType.Id) {
      addToIdOfIntrest(cleanTarget, key);
    }
  });
};
const removeTargetOfInteresetByKey = key => {
  if (targetsOfIntrest.has(key)) {
    targetsOfIntrest.delete(key);
  }
  clearupClassOfIntrest(key);
  clearupIdOfIntrest(key);
};
const hasClassNameOfInterest = classname => classOfIntrest.has(classname);
const hasIdOfInterest = id => idOfIntrest.has(id);
const hasTargetOfInterest = target => {
  const targetSplit = breakDownTargets(target).flat();
  let a = false;
  targetSplit.forEach(target => {
    const [targetType, cleanTarget] = breakDownTarget(target);
    if (targetType === TargetType.Classname) {
      a = a || hasClassNameOfInterest(cleanTarget);
    } else if (targetType === TargetType.Id) {
      a = a || hasIdOfInterest(cleanTarget);
    }
  });
  return a;
};
const getTargestFromMap = (map, value) => {
  const targets = [];
  if (map.has(value)) {
    map.get(value).forEach(target => {
      if (targetsOfIntrest.has(target)) {
        targets.push(targetsOfIntrest.get(target));
      }
    });
  }
  return targets;
};
const getTargetsForClassname = classname => getTargestFromMap(classOfIntrest, classname);
const getTargetsForId = id => getTargestFromMap(idOfIntrest, id);
const getInterestsSize = () => targetsOfIntrest.size;

setGlobal('svs.templates.primaryOnboarding.target', {
  addTargetOfInterest,
  removeTargetOfInteresetByKey,
  hasClassNameOfInterest,
  hasIdOfInterest,
  hasTargetOfInterest,
  getTargetsForClassname,
  getTargetsForId,
  getInterestsSize
});

 })(window);