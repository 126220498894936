(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/onboarding-info.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/onboarding-info.js');
"use strict";

const {
  verifyValue,
  verifyObject
} = svs.components.lbUtils.verify;
const {
  hintVerifyType,
  TargetType
} = svs.templates.primaryOnboarding.constants;
const {
  findMap,
  breakDownTarget
} = svs.templates.primaryOnboarding.utils;
const {
  removeTargetOfInteresetByKey
} = svs.templates.primaryOnboarding.target;


const onboardingInfo = new Map();
const classNameToHints = new Map();
const idToHints = new Map();
const getHintFromKey = key => onboardingInfo.get(key);
const getHintsFromClassname = classname => {
  const keys = classNameToHints.get(classname);
  if (keys) {
    const hints = [];
    keys.forEach(key => {
      const hint = getHintFromKey(key);
      if (hint) {
        hints.push(hint);
      }
    });
    return hints;
  }
  return undefined;
};
const getHintsFromId = id => {
  const keys = idToHints.get(id);
  if (keys) {
    const hints = [];
    keys.forEach(key => {
      hints.push(getHintFromKey(key));
    });
    return hints.filter(v => v);
  }
  return undefined;
};
const getHintsFromTarget = target => {
  const [targetType, targetClean] = breakDownTarget(target);
  if (targetType === TargetType.Classname) {
    return getHintsFromClassname(targetClean);
  } else if (targetType === TargetType.Id) {
    return getHintsFromId(targetClean);
  }
  return undefined;
};
const classAndIdMapping = (target, key, classMapFn, idMapFn) => {
  const [targetType, targetClean] = breakDownTarget(target);
  if (targetType === TargetType.Classname) {
    classMapFn(targetClean, key);
  } else if (targetType === TargetType.Id) {
    idMapFn(targetClean, key);
  }
};
const addTargetRef = (target, key) => {
  classAndIdMapping(target, key, (targetClean, key) => {
    const set = classNameToHints.get(targetClean);
    if (!set) {
      classNameToHints.set(targetClean, new Set([key]));
    } else {
      set.add(key);
    }
  }, (targetClean, key) => {
    const set = idToHints.get(targetClean);
    if (!set) {
      idToHints.set(targetClean, new Set([key]));
    } else {
      set.add(key);
    }
  });
};
const hasHintToId = id => idToHints.has(id) ? id : undefined;
const hasHintToClassName = classname => classNameToHints.has(classname) ? classname : undefined;
const removeIdOfInterest = id => {
  removeTargetOfInteresetByKey(idToHints.get(id));
};
const removeClassNameOfInterest = classname => {
  removeTargetOfInteresetByKey(classNameToHints.get(classname));
};
const removeTargetOfInterest = target => {
  const [targetType, targetClean] = breakDownTarget(target);
  if (targetType === TargetType.Classname) {
    removeClassNameOfInterest(targetClean);
  } else if (targetType === TargetType.Id) {
    removeIdOfInterest(targetClean);
  }
};
const setOnboardingInfo = (key, value) => {
  verifyValue(key).isString();
  verifyObject(value).schema(hintVerifyType);
  onboardingInfo.set(key, value);
};
const forEachOnboardingInfo = fn => {
  onboardingInfo.forEach(fn);
};
const findOnboardingInfo = fn => findMap(onboardingInfo, fn);

setGlobal('svs.templates.primaryOnboarding.onboardingInfo', {
  getHintFromKey,
  getHintsFromClassname,
  getHintsFromId,
  getHintsFromTarget,
  addTargetRef,
  hasHintToId,
  hasHintToClassName,
  removeIdOfInterest,
  removeClassNameOfInterest,
  removeTargetOfInterest,
  setOnboardingInfo,
  forEachOnboardingInfo,
  findOnboardingInfo
});

 })(window);