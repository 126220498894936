(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/constants.js');
"use strict";

const {
  VerifyType
} = svs.components.lbUtils.verify.constants;

const hintVerifyType = {
  key: VerifyType.NotEmptyString,
  target: VerifyType.NotEmptyString,
  text: [VerifyType.NotEmptyString]
};
const TargetType = {
  Classname: 'classname',
  Id: 'id'
};
const TargetDelimiterMap = {
  [TargetType.Classname]: '.',
  [TargetType.Id]: '#'
};
const CharToTargetTypeMap = {
  [TargetDelimiterMap[TargetType.Classname]]: TargetType.Classname,
  [TargetDelimiterMap[TargetType.Id]]: TargetType.Id
};
const TemplateSelectors = {
  TemplateContent: '#onboarding_content',
  TemplateFullscreen: '#onboarding_fullscreen',
  MainContent: '#main-content'
};
const HintVisibility = {
  None: 'None',
  Available: 'Available',
  Visible: 'Visible'
};
const hintLogicFields = {
  isVisible: false,
  isAvailable: false,
  visibility: HintVisibility.None,
  hasTriggered: false,
  targetEl: null,
  tooltipEl: null,
  child: undefined,
  isBlocked: false
};
const baseHintItem = {
  key: undefined,
  target: undefined,
  count: 0,
  displaycount: 0,
  dependency: undefined,
  text: [],
  placement: 'top',
  preventClick: false,
  timeout: 0
};

setGlobal('svs.templates.primaryOnboarding.constants', {
  hintVerifyType,
  TargetType,
  TargetDelimiterMap,
  CharToTargetTypeMap,
  TemplateSelectors,
  HintVisibility,
  hintLogicFields,
  baseHintItem
});

 })(window);