(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/templates/primary-onboarding/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/templates/primary-onboarding/assets/javascripts/utils.js');
"use strict";

const {
  TargetType,
  TargetDelimiterMap,
  CharToTargetTypeMap
} = svs.templates.primaryOnboarding.constants;

const findMap = (map, fn) => {
  const iterator = map.entries();
  let done = false;
  let obj;
  do {
    const next = iterator.next();
    const v = next.value;
    done = next.done;
    if (v && fn(v)) {
      obj = v;
    }
  } while (!done && !obj);
  return obj;
};
const allNonBracketCharFromStart = /^([^[]+)/;
const breakDownTarget = target => {
  const firstChar = target[0];
  const cleanTarget = target.substring(1);
  return [CharToTargetTypeMap[firstChar], allNonBracketCharFromStart.test(cleanTarget) ? allNonBracketCharFromStart.exec(cleanTarget)[1] : cleanTarget];
};
const classNameToArray = className => {
  if (typeof className !== 'string' && !(className instanceof String)) {
    className = '';
  }
  return className.trim().split(' ');
};
const compareChangesObject = function (a, b, changes) {
  let changeModelFn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : (a, b) => ({
    a,
    b
  });
  const entries = Object.entries(a);
  return entries.reduce((prev, _ref) => {
    let [key, valueA] = _ref;
    const valueB = b[key];
    if (valueB !== valueA && !prev.has(key)) {
      prev.set(key, changeModelFn(valueA, valueB));
    }
    return prev;
  }, changes);
};
const compareChangesArray = function (a, b, changes) {
  let changeModelFn = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : (a, b) => ({
    a,
    b
  });
  return a.reduce((prev, valueA) => {
    const valueB = b.find(v => v === valueA);
    if (valueB !== valueA && !prev.has(valueA)) {
      prev.set(valueA, changeModelFn(valueA, valueB));
    }
    return prev;
  }, changes);
};
const checkChanges = (oldObj, newObj) => {
  const changes = new Map();
  if (oldObj.constructor.name === 'Array') {
    compareChangesArray(oldObj, newObj, changes, (oldValue, newValue) => ({
      oldValue,
      newValue
    }));
    compareChangesArray(newObj, oldObj, changes, (newValue, oldValue) => ({
      oldValue,
      newValue
    }));
  } else {
    compareChangesObject(oldObj, newObj, changes, (oldValue, newValue) => ({
      oldValue,
      newValue
    }));
    compareChangesObject(newObj, oldObj, changes, (newValue, oldValue) => ({
      oldValue,
      newValue
    }));
  }
  return changes;
};
const delimiterLogic = (s, delimiter, appendDelimiters) => {
  const sTrimmed = s.trim();
  if (appendDelimiters) {
    return appendDelimiters.includes(sTrimmed[0]) ? sTrimmed : "".concat(delimiter).concat(sTrimmed);
  }
  return sTrimmed;
};
const splitStringOnDelimiter = (s, delimiter, appendDelimiters, postFn) => {
  if (s.indexOf(delimiter) < 0) {
    const sFixed = delimiterLogic(s, delimiter, appendDelimiters);
    if (!postFn) {
      return [sFixed];
    }
    const arr = postFn(sFixed);
    return Array.isArray(arr) ? arr.flat() : [arr];
  }
  return s.split(delimiter).filter(s => s && s.length > 0).map(s => {
    const sFixed = delimiterLogic(s, delimiter, appendDelimiters);
    return !postFn ? sFixed : postFn(sFixed);
  }).flat();
};
const splitCsvString = (s, postFn) => splitStringOnDelimiter(s, ',', undefined, postFn);
const splitClassnameString = (s, postFn) => splitStringOnDelimiter(s, TargetDelimiterMap[TargetType.Classname], [TargetDelimiterMap[TargetType.Classname], TargetDelimiterMap[TargetType.Id]], postFn);
const splitIdString = (s, postFn) => splitStringOnDelimiter(s, TargetDelimiterMap[TargetType.Id], [TargetDelimiterMap[TargetType.Classname], TargetDelimiterMap[TargetType.Id]], postFn);

setGlobal('svs.templates.primaryOnboarding.utils', {
  findMap,
  breakDownTarget,
  classNameToArray,
  checkChanges,
  splitCsvString,
  splitClassnameString,
  splitIdString
});

 })(window);